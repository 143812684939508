import confetti from 'canvas-confetti';
/* It renders the game based on the current game state */
var Renderer = /** @class */ (function () {
    /**
     * Initialize the renderer.
     * @param gameContainer - This is the HTML element that will contain the game.
     */
    function Renderer(game, gameContainer, numberOfMinesElement, flagsElement, timerElement) {
        var _this = this;
        /** All cells rendered as HTML elements  */
        this.cellsHTML = [];
        /** Indicates whether the confetti animation already rendered */
        this.confettiRendered = false;
        this.game = game;
        this.gameContainer = gameContainer;
        this.numberOfMinesElement = numberOfMinesElement;
        this.flagsElement = flagsElement;
        this.timerElement = timerElement;
        window.setInterval(function () { return _this.renderTimer(); }, 1000);
    }
    /**
     * Renders the game based on the current game state.
     */
    Renderer.prototype.render = function () {
        this.numberOfMinesElement.innerHTML = this.game.numberOfMines.toString();
        this.flagsElement.innerHTML = this.game.flaggedCells.length.toString();
        switch (this.game.gameState) {
            case 'INITIALIZING':
                if (this.cellsHTML.length === 0)
                    this.renderInitializing();
                break;
            case 'PLAYING':
                this.renderPlaying();
                break;
            case 'LOST':
                this.renderLost();
                break;
            case 'WON':
                this.renderWon();
                break;
        }
    };
    /**
     * Renders the timer.
     */
    Renderer.prototype.renderTimer = function () {
        var twoDigit = function (number) {
            return number.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false,
            });
        };
        var seconds = Math.round(this.game.timeElapsed / 1000);
        var minutes = Math.floor(seconds / 60);
        var secondsLeft = seconds % 60;
        this.timerElement.innerHTML = "".concat(twoDigit(minutes), ".").concat(twoDigit(secondsLeft));
    };
    /**
     * Reveals all cells of the game and removes the flags.
     */
    Renderer.prototype.revealAll = function () {
        var _this = this;
        this.game.cells.forEach(function (cell) {
            var cellHTML = _this.cellsHTML[cell.index];
            cellHTML.classList.add('revealed');
            cellHTML.classList.remove('flagged');
            cellHTML.innerHTML = cell.adjacentMines.toString();
            if (cell.isMine)
                cellHTML.innerHTML = '💣';
        });
    };
    /**
     * Generates the cells and adds them to the gameContainer.
     */
    Renderer.prototype.renderInitializing = function () {
        var _this = this;
        this.gameContainer.style.setProperty('--cols', this.game.numberOfCols.toString());
        this.gameContainer.style.setProperty('--rows', this.game.numberOfCols.toString());
        this.game.field.flat().forEach(function () {
            var cellHTML = document.createElement('div');
            cellHTML.classList.add('cell');
            _this.gameContainer.appendChild(cellHTML);
            _this.cellsHTML.push(cellHTML);
        });
    };
    /**
     * Renders all cells based on their properties.
     */
    Renderer.prototype.renderPlaying = function () {
        var _this = this;
        this.game.cells.forEach(function (cell) {
            var cellHTML = _this.cellsHTML[cell.index];
            if (cell.isRevealed)
                cellHTML.innerHTML = cell.adjacentMines.toString();
            else if (cell.isFlagged)
                cellHTML.innerHTML = '🚩';
            else
                cellHTML.innerHTML = '';
            cellHTML.classList.toggle('mine', cell.isMine);
            cellHTML.classList.toggle('revealed', cell.isRevealed);
            cellHTML.classList.toggle('flagged', cell.isFlagged);
        });
    };
    Renderer.prototype.renderWon = function () {
        var _a, _b;
        this.revealAll();
        if (this.confettiRendered)
            return;
        this.confettiRendered = true;
        var canvas = document.createElement('canvas');
        canvas.width = document.body.clientWidth;
        canvas.height = document.body.clientHeight;
        canvas.className = 'confetti-canvas';
        document.body.appendChild(canvas);
        var myConfetti = confetti.create(canvas);
        (_b = (_a = myConfetti({
            particleCount: 200,
            spread: 100,
        })) === null || _a === void 0 ? void 0 : _a.then(function () { return canvas.remove(); })) === null || _b === void 0 ? void 0 : _b.catch(console.error);
    };
    Renderer.prototype.renderLost = function () {
        this.revealAll();
    };
    return Renderer;
}());
export default Renderer;
