import '../scss/base.scss';
import '../scss/game.scss';
import getDifficulty from './getDifficulty';
import Minesweeper from './Minesweeper';
import Renderer from './Renderer';
var difficulty = getDifficulty();
var numberOfRows = difficulty.numberOfRows, numberOfCols = difficulty.numberOfCols, numberOfMines = difficulty.numberOfMines;
var game = new Minesweeper(numberOfRows, numberOfCols, numberOfMines);
var gameContainer = document.getElementById('gameContainer');
var numberOfMinesSPAN = document.getElementById('numberOfMines');
var flagsSPAN = document.getElementById('flags');
var timerSPAN = document.getElementById('timer');
var renderer = new Renderer(game, gameContainer, numberOfMinesSPAN, flagsSPAN, timerSPAN);
renderer.render();
renderer.cellsHTML.forEach(function (cell, index) {
    cell.addEventListener('click', function () {
        // reveal cell and render the game
        game.revealCell(index);
        renderer.render();
    });
    cell.addEventListener('contextmenu', function (e) {
        // prevent context menu from opening
        e.preventDefault();
        // flag cell and render the game
        game.flagCell(index);
        renderer.render();
    });
});
