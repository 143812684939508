var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { v4 as uuid } from 'uuid';
/** Representation of a Minesweeper game */
var Minesweeper = /** @class */ (function () {
    /**
     * The constructor function creates a new Minesweeper object with the specified number of rows,
     * columns, and mines
     * @param numberOfRows - The number of rows in the field.
     * @param numberOfCols - The number of columns in the field.
     * @param numberOfMines - The number of mines to be placed on the field.
     */
    function Minesweeper(numberOfRows, numberOfCols, numberOfMines) {
        /** The current state of the game */
        this.gameState = 'INITIALIZING';
        /** The field of the game */
        this.field = [];
        /** When the game state changed to "PLAYING" */
        this.startTimestamp = null;
        /** Last measured timestamp */
        this.lastTimestamp = null;
        this.numberOfRows = numberOfRows;
        this.numberOfCols = numberOfCols;
        this.numberOfMines = numberOfMines;
        this.createField();
    }
    Object.defineProperty(Minesweeper.prototype, "timeElapsed", {
        /**
         * Returns the number of seconds that have elapsed since the game started.
         */
        get: function () {
            if (this.startTimestamp === null || this.lastTimestamp === null)
                return 0;
            if (this.gameState === 'PLAYING')
                this.lastTimestamp = Date.now();
            return this.lastTimestamp - this.startTimestamp;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Minesweeper.prototype, "cells", {
        /**
         * It returns an array of all the cells in the field
         * @returns An array of all the cells in the field.
         */
        get: function () {
            return this.field.flat();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Minesweeper.prototype, "flaggedCells", {
        /**
         * It returns an array of all the cells in the field that are flagged
         * @returns An array of all the cells that are flagged.
         */
        get: function () {
            return this.field.flat().filter(function (cell) { return cell.isFlagged; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Minesweeper.prototype, "revealedCells", {
        /**
         * It returns an array of all the cells in the field that have been revealed
         * @returns An array of all the cells that are revealed.
         */
        get: function () {
            return this.field.flat().filter(function (cell) { return cell.isRevealed; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Minesweeper.prototype, "unrevealedCells", {
        /**
         * It returns an array of all the cells in the field that are not yet revealed
         * @returns An array of all the cells that are not revealed.
         */
        get: function () {
            return this.field.flat().filter(function (cell) { return !cell.isRevealed; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Minesweeper.prototype, "mines", {
        /**
         * It returns an array of all the cells that are mines
         * @returns An array of all the cells that are mines.
         */
        get: function () {
            return this.field.flat().filter(function (cell) { return cell.isMine; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Minesweeper.prototype, "isWon", {
        /**
         * If all the mines are flagged and the number of mines is equal to the number of flagged cells, then
         * the game is won
         * @returns A boolean value.
         */
        get: function () {
            return (this.mines.every(function (cell) { return cell.isFlagged; }) &&
                this.mines.length === this.flaggedCells.length);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Minesweeper.prototype, "isLost", {
        /**
         * Indicates that the player lost the game.
         * @returns Whether the player lost the game.
         */
        get: function () {
            return this.gameState === 'LOST';
        },
        enumerable: false,
        configurable: true
    });
    /**
     * Creates the field with plain cells.
     */
    Minesweeper.prototype.createField = function () {
        // create plain field
        for (var rowIndex = 0; rowIndex < this.numberOfRows; rowIndex++) {
            this.field[rowIndex] = [];
            for (var colIndex = 0; colIndex < this.numberOfCols; colIndex++) {
                // create plain cell
                this.field[rowIndex][colIndex] = {
                    id: uuid(),
                    isMine: false,
                    isRevealed: false,
                    isFlagged: false,
                    adjacentMines: 0,
                    row: rowIndex,
                    col: colIndex,
                    index: rowIndex * this.numberOfCols + colIndex,
                };
            }
        }
    };
    /**
     * Updates the gameState if it is the first click of the game.
     * @param cell The first cell that the player clicked on.
     */
    Minesweeper.prototype.onFirstClick = function (cell) {
        // update game state
        if (this.gameState === 'INITIALIZING') {
            this.gameState = 'PLAYING';
            this.startTimestamp = Date.now();
            this.lastTimestamp = Date.now();
            // place mines
            this.placeMines(cell);
        }
    };
    /**
     * Places mines at random cells in the field, ensuring that the starting cell, passed as an argument, is not filled.
     * @param startCell - The cell that the user clicked on.
     */
    Minesweeper.prototype.placeMines = function (startCell) {
        // Flatten the field and remove the starting cell
        var possibleCells = this.field.flat().filter(function (cell) { return cell !== startCell; });
        // Place mines at random cells
        for (var i = 0; i < this.numberOfMines; i++) {
            var randomIndex = Math.floor(Math.random() * possibleCells.length);
            var cell = possibleCells[randomIndex];
            cell.isMine = true;
            // Update the number of mines around the cell
            this.getAdjacentCells(cell.row, cell.col).forEach(function (cellAround) { return cellAround.adjacentMines++; });
            // Remove the cell from the possible cells
            possibleCells.splice(randomIndex, 1);
        }
    };
    /**
     * It takes an index and returns the cell at that index
     * @param index - The index of the cell you want to get.
     * @returns The cell at the given index.
     */
    Minesweeper.prototype.getCellByIndex = function (index) {
        var row = Math.floor(index / this.numberOfCols);
        var col = index % this.numberOfCols;
        return this.field[row][col];
    };
    /**
     * It returns an array of the cells around a given cell
     * @param row - the row of the cell we want to get the neighbors of
     * @param col - the column of the cell
     * @param [pattern=block] - the pattern of the cells we want to get the neighbors of
     * @returns An array of cells around the given cell.
     */
    Minesweeper.prototype.getAdjacentCells = function (row, col, pattern) {
        var _this = this;
        if (pattern === void 0) { pattern = 'block'; }
        // the cells that actually touch the given cell, including the given cell
        var crossPattern = [
            [row - 1, col],
            [row, col - 1],
            [row, col],
            [row, col + 1],
            [row + 1, col],
        ];
        // all the 8 cells around the given cell, even in the corners, including the given cell
        var blockPattern = __spreadArray(__spreadArray([], crossPattern, true), [
            [row - 1, col - 1],
            [row - 1, col + 1],
            [row + 1, col - 1],
            [row + 1, col + 1],
        ], false);
        /**
         * It returns true if the given coordinates are within the bounds of the field
         */
        var positionInField = function (_a) {
            var cRow = _a[0], cCol = _a[1];
            return cRow >= 0 &&
                cCol >= 0 &&
                cRow < _this.numberOfRows &&
                cCol < _this.numberOfCols;
        };
        // choose which pattern to use and filter all positions that are not in the field
        var positions = (pattern === 'block' ? blockPattern : crossPattern).filter(positionInField);
        // return the cells at the given positions
        return positions.map(function (_a) {
            var rowAround = _a[0], colAround = _a[1];
            return _this.field[rowAround][colAround];
        });
    };
    /** Reveals all cells that have zero mines in their surrounding and are directly connect.
     * @param cell - The cell that has zero mines in its surrounding.
     */
    Minesweeper.prototype.revealZeros = function (cell) {
        var _this = this;
        // reveals current cell
        cell.isRevealed = true;
        // gets directly connected cells and reveals them if they have zero mines in their surrounding
        this.getAdjacentCells(cell.row, cell.col, 'cross').forEach(function (cellAround) {
            if (cellAround.adjacentMines === 0 &&
                !cellAround.isRevealed &&
                !cellAround.isFlagged) {
                _this.revealZeros(cellAround);
            }
        });
    };
    /**
     * _won() sets the gameState to "WON"
     */
    Minesweeper.prototype.won = function () {
        this.gameState = 'WON';
    };
    /**
     * _gameOver() is a function that sets the gameState to "LOST" when the player loses the game
     */
    Minesweeper.prototype.gameOver = function () {
        this.gameState = 'LOST';
    };
    /**
     * If the game is not over, and the cell is not revealed or flagged, then reveal the cell
     * @param index - The index of the cell to reveal.
     */
    Minesweeper.prototype.revealCell = function (index) {
        var cell = this.getCellByIndex(index);
        if (this.gameState === 'LOST' ||
            this.gameState === 'WON' ||
            cell.isRevealed ||
            cell.isFlagged)
            return;
        this.onFirstClick(cell);
        // reveal field
        cell.isRevealed = true;
        // check if the game is won
        if (cell.isMine)
            this.gameOver();
        // if the cell is empty, reveal all the cells around it
        else if (cell.adjacentMines === 0)
            this.revealZeros(cell);
    };
    /**
     * Toggles the flag on the cell.
     * @param index - The index of the cell to be flagged.
     */
    Minesweeper.prototype.flagCell = function (index) {
        var cell = this.getCellByIndex(index);
        if (this.gameState !== 'PLAYING' || cell.isRevealed)
            return;
        // flag cell
        cell.isFlagged = !cell.isFlagged;
        // check if game is won
        if (this.isWon)
            this.won();
    };
    return Minesweeper;
}());
export default Minesweeper;
