import { defaultDifficulty, getDifficultyByLevel, } from './difficulties';
/**
 * It returns the difficulty level of the game, which is either the difficulty level specified in the
 * URL, or the default difficulty level
 * @returns The difficulty object is being returned.
 */
function getDifficulty() {
    var searchParams = new URLSearchParams(window.location.search);
    var level = searchParams.get('difficulty');
    var numberOfRows = Number(searchParams.get('numberOfRows'));
    var numberOfCols = Number(searchParams.get('numberOfCols'));
    var numberOfMines = Number(searchParams.get('numberOfMines'));
    var difficulty = (getDifficultyByLevel(level || defaultDifficulty.level));
    difficulty.numberOfCols = numberOfCols || difficulty.numberOfCols;
    difficulty.numberOfRows = numberOfRows || difficulty.numberOfRows;
    difficulty.numberOfMines = numberOfMines || difficulty.numberOfMines;
    if (numberOfRows || numberOfCols || numberOfMines)
        difficulty.level = 'custom';
    return difficulty;
}
export default getDifficulty;
