export var easyDifficulty = {
    numberOfRows: 9,
    numberOfCols: 9,
    numberOfMines: 10,
    level: 'easy',
};
export var mediumDifficulty = {
    numberOfRows: 15,
    numberOfCols: 15,
    numberOfMines: 40,
    level: 'medium',
};
export var hardDifficulty = {
    numberOfRows: 15,
    numberOfCols: 15,
    numberOfMines: 99,
    level: 'hard',
};
export var allDifficulties = [
    easyDifficulty,
    mediumDifficulty,
    hardDifficulty,
];
export var getDifficultyByLevel = function (level) {
    return allDifficulties.find(function (difficulty) { return difficulty.level === level; });
};
export var defaultDifficulty = easyDifficulty;
export default {
    easyDifficulty: easyDifficulty,
    mediumDifficulty: mediumDifficulty,
    hardDifficulty: hardDifficulty,
};
